import { Injectable } from '@angular/core';

import { NavController } from '@ionic/angular';
import { Store } from '@ngxs/store';

import { AuthState } from '@shared/state';

@Injectable({ providedIn: 'root' })
export class NoAuthGuard {
    constructor(
        private store: Store,
        private navCtrl: NavController,
    ) {}

    canActivate() {
        const user = this.store.selectSnapshot(AuthState.user);

        if (!user) {
            return true;
        }

        this.navCtrl.navigateRoot('/');

        return false;
    }
}
