import { File } from './file';
import { Model } from './model';

export class Advertiser extends Model {
    file: File;
    level: number;
    url: string;

    fields = ['level', 'url'];

    constructor(data?) {
        super();

        if (data?.file) {
            this.file = new File(data.file);
        }

        this.initializeModel(data);
    }
}
