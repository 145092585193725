import { Injectable, InjectionToken } from '@angular/core';
import { TSuiteHttpDefaultHeaders, TSuiteHttpModuleOptions } from './symbols';

export const FOR_ROOT_OPTIONS = new InjectionToken<TSuiteHttpModuleOptions>('forRoot() ApiModule configuration');

@Injectable({ providedIn: 'root' })
export class TSuiteHttpConfigService {
    private options: TSuiteHttpModuleOptions;

    constructor() {}

    get baseUrl(): string {
        return this.options.baseUrl;
    }

    // get devPath(): string {
    //     return this.options.devPath;
    // }

    get defaultHeaders(): TSuiteHttpDefaultHeaders {
        return this.options.defaultHeaders;
    }

    setOptions(options: TSuiteHttpModuleOptions) {
        this.options = options;
    }
}
