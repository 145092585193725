import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function socialSecurityNumberValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const { value } = control;

        if (!value) {
            return null;
        }

        const valueIsOnlyNumbers = /^\d+$/.test(value);
        const valueIsTwelveDigits = value.length === 12;

        const valid = valueIsOnlyNumbers && valueIsTwelveDigits;

        return valid ? null : { socialSecurityNumber: true };
    };
}
