import { Model } from './model';

export class Role extends Model {
    title: string;
    permissions: string[];

    fields = ['title', 'permissions'];

    constructor(data?) {
        super();

        this.initializeModel(data);
    }
}
