import { Model } from './model';
import { Role } from './role';

export class User extends Model {
    email: string;
    verified: boolean;
    password: string;
    firstName: string;
    lastName: string;
    fullName: string;

    roles: Role[];

    fields = ['email', 'verified', 'password', 'firstName', 'lastName'];

    constructor(data?) {
        super();

        this.initializeModel(data);

        if (data?.roles) {
            this.roles = data.roles.map((role) => new Role(role));
        }

        this.fullName = `${this.firstName} ${this.lastName}`;
    }
}
