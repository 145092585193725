export class Model {
    defaultFields: any = ['id', 'uuid', 'updatedAt', 'createdAt'];

    fields: string[] = [];
    meta: any;

    id: number;
    uuid: string;
    updatedAt: string;
    createdAt: string;

    constructor() {}

    initializeModel(data): void {
        this.setProperties(data);
    }

    getProperties(): any {
        const ret = {};

        for (const field of this.fields) {
            if (this.hasOwnProperty(field)) {
                ret[field] = this[field];
            }
        }

        return ret;
    }

    setProperties(data): void {
        /**
         * Parse data
         */
        this.fields.map((field) => {
            if (data && (data[field] || data[field] === false)) {
                this[field] = data[field];
            }
        });

        this.defaultFields.map((field) => {
            if (data && (data[field] || data[field] === false)) {
                this[field] = data[field];
            }
        });

        /**
         * Clean up model
         */
        delete this.fields;
        delete this.defaultFields;
    }
}
