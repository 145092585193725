import { Activity } from './activity';
import { Model } from './model';
import { User } from './user';

export class Comment extends Model {
    content: string;
    user: User;
    activity: Activity;

    fields = ['content'];

    constructor(data?) {
        super();

        this.initializeModel(data);

        if (data?.user) {
            this.user = new User(data.user);
        }

        if (data?.activity) {
            this.activity = new Activity(data.activity);
        }
    }
}
