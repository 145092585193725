export interface TSuiteHttpModuleOptions {
    baseUrl: string;
    // devPath?: string;
    defaultHeaders: TSuiteHttpDefaultHeaders;
    timeout?: number;
}

export enum TSuiteHttpRequestMethod {
    Get = 'GET',
    Post = 'POST',
    Put = 'PUT',
    Patch = 'PATCH',
    Delete = 'DELETE',
}

// export interface TSuiteCollection<T> {
//     next: boolean;
//     page: number;
//     data: T[];
// }

export interface TSuiteHttpDefaultHeaders {
    'content-type'?: string;
    source?: string;
    timezone?: string;
    container?: string;
}
