import { Model } from './model';

export class Reservation extends Model {
    address: string;
    adminComment: string;
    city: string;
    country: string;
    email: string;
    finalPaymentAmount: number;
    finalPaymentDate: string;
    firstName: string;
    handBinoculars: boolean;
    lastName: string;
    miscInfo: string;
    paymentStatus: any;
    phoneNumber: string;
    postalCode: string;
    registrationFeePaymentAmount: number;
    registrationFeePaymentDate: string;
    scopeBinoculars: boolean;
    singleBedroom: boolean;
    socialSecurityNumber: string;
    suggestDoubleRoomPartner: boolean;
    suggestedDoubleRoomPartner: string;
    transactionUuid: string;
    type: string;
    finalInvoiceDate: string;
    personalInformationForm: any;

    fields = [
        'address',
        'adminComment',
        'city',
        'country',
        'email',
        'finalPaymentAmount',
        'finalPaymentDate',
        'firstName',
        'handBinoculars',
        'lastName',
        'miscInfo',
        'paymentStatus',
        'phoneNumber',
        'postalCode',
        'registrationFeePaymentAmount',
        'registrationFeePaymentDate',
        'scopeBinoculars',
        'singleBedroom',
        'socialSecurityNumber',
        'suggestDobuleRoomPartner',
        'suggestedDoubleRoomPartner',
        'transactionUuid',
        'type',
        'finalInvoiceDate',
        'personalInformationForm',
    ];

    constructor(data?) {
        super();

        this.initializeModel(data);
    }
}
