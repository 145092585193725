export class Authenticate {
    static type = '[Auth] Authenticate';

    constructor(public credentials: { email: string; password: string }) {}
}

export class AuthenticationSuccessful {
    static type = '[Auth] Authentication successful';
}

export class AuthencticationUnsuccessful {
    static type = '[Auth] Authentication unsuccessful';
}

export class SignOut {
    static type = '[Auth] Sign out';
}

export class SetToken {
    static type = '[Auth] Set token';

    constructor(public token: string) {}
}

export class RefreshToken {
    static type = '[Auth] Refresh token';
}
