import { Model } from './model';

export class Participant extends Model {
    firstName: string;
    lastName: string;
    address: string;
    city: string;
    postalCode: string;
    phoneNumber: string;
    email: string;
    miscInfo: string;
    country: string;
    socialSecurityNumber: string;
    handBinoculars: boolean;
    scopeBinoculars: boolean;
    suggestDoubleRoomPartner: boolean;
    suggestedDoubleRoomPartner: string;
    singleBedroom: boolean;
    type: string;

    fields = [
        'firstName',
        'lastName',
        'address',
        'city',
        'postalCode',
        'phoneNumber',
        'email',
        'miscInfo',
        'handBinoculars',
        'scopeBinoculars',
        'country',
        'socialSecurityNumber',
        'suggestDoubleRoomPartner',
        'suggestedDoubleRoomPartner',
        'singleBedroom',
        'type',
    ];

    constructor(data?) {
        super();

        this.initializeModel(data);

        if (this.firstName) {
            this.firstName = this.firstName.charAt(0).toUpperCase() + this.firstName.slice(1);
        }

        if (this.lastName) {
            this.lastName = this.lastName.charAt(0).toUpperCase() + this.lastName.slice(1);
        }
    }
}
