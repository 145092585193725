import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { TSuiteHttpRequestMethod } from './symbols';
import { TSuiteHttpRequest } from './t-suite-http-request';
import { TSuiteHttpConfigService } from './t-suite-http.config';

@Injectable({ providedIn: 'root' })
export class TSuiteHttpService {
    public headers: HttpHeaders;

    constructor(
        @Inject(HttpClient) public http: HttpClient,
        public httpConfig: TSuiteHttpConfigService,
    ) {
        this.headers = new HttpHeaders({ ...this.httpConfig.defaultHeaders });
    }

    delete(endpoint: string, id?: string | number): TSuiteHttpRequest {
        endpoint = id ? [endpoint, id].join('/') : endpoint;

        return new TSuiteHttpRequest(this).url(endpoint).method(TSuiteHttpRequestMethod.Delete);
    }

    get(endpoint: string): TSuiteHttpRequest {
        return new TSuiteHttpRequest(this).url(endpoint).method(TSuiteHttpRequestMethod.Get);
    }

    post(endpoint: string, body: any): TSuiteHttpRequest {
        return new TSuiteHttpRequest(this).url(endpoint).body(body).method(TSuiteHttpRequestMethod.Post);
    }

    put(endpoint: string, body: any, id?: string | number): TSuiteHttpRequest {
        endpoint = id ? [endpoint, id].join('/') : endpoint;

        return new TSuiteHttpRequest(this).url(endpoint).body(body).method(TSuiteHttpRequestMethod.Put);
    }

    show(endpoint: string, id: string | number): TSuiteHttpRequest {
        return this.get([endpoint, id].join('/'));
    }

    setDefaultHeader(key: string, value: string) {
        if (value) {
            this.headers = this.headers.set(key.toLowerCase(), value);
        } else if (this.headers.has(key.toLowerCase())) {
            this.headers = this.headers.delete(key.toLowerCase());
        }
    }

    setToken(token: string): void {
        this.setDefaultHeader('token', token);
    }
}
