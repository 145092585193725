import { Routes } from '@angular/router';

import { AuthGuard, NoAuthGuard } from '@shared/guards';

export const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('@public/views/routes').then((m) => m.routes),
    },
    {
        path: 'admin',
        canActivate: [AuthGuard],
        loadChildren: () => import('@admin/views/routes').then((m) => m.routes),
    },
    {
        path: 'auth',
        canActivate: [NoAuthGuard],
        loadComponent: () => import('@shared/views/auth/auth.page').then((m) => m.AuthPage),
    },
    {
        path: 'fil/:url',
        loadComponent: () => import('@shared/views/file/file.page').then((m) => m.FilePage),
    },
];
