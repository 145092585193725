import { Model } from './model';

export class Folder extends Model {
    title: string;
    children: Folder[] = [];
    parent: Folder;
    parentUuid: string;

    fields = ['title', 'parentUuid'];

    constructor(data?) {
        super();

        if (data?.children) {
            this.children = data.children.map((child) => new Folder(child));
        }

        if (data?.parent) {
            this.parent = new Folder(data.parent);
        }

        this.initializeModel(data);
    }
}
