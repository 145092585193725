export class GetOrganisation {
    static type = '[App] Get organisation';
}

export class HideTabs {
    static type = '[App] Hide tabs';
}

export class ShowTabs {
    static type = '[App] Show tabs';
}
