import { add, isBefore } from 'date-fns';

import * as moment from 'moment';

import { File } from './file';
import { Model } from './model';
import { Reservation } from './reservation';
import { User } from './user';

export class Activity extends Model {
    title: string;
    description: string;
    type: 'trip' | 'course' | 'guiding';
    images: {
        position: number;
        url: string;
    }[];
    files: {
        position: number;
        url: string;
        mime: string;
        title: string;
    }[];
    infoFile: {
        url: string;
        mime: string;
        title: string;
    };
    country: {
        title: string;
        code: string;
    };
    primaryImage: string;
    guide: string;
    accomodation: string;
    transport: string;
    amountMin: number;
    amountMax: number;
    price: number;
    startDate: string;
    endDate: string;
    startTime: string;
    endTime: string;
    femalesOnly: boolean;
    reserveSpotsOnly: boolean;
    hidden: boolean;
    fullyBooked: boolean;
    difficulty: string;
    registrationFee: number;
    singleBedroomFee: number;
    priceInformation: string;
    plus: boolean;
    bookingUrl: string;
    partner: string;
    newFromDate: string;
    timespan: string;
    projectNumber: string;
    reservations: Reservation[];
    media: File[];
    miscMedia: File[];
    primaryMedia: File;
    infoMedia: File;
    users: User[];
    adminComment: string;
    ownerComment: string;

    isNew = false;

    reservationCount: number;

    isStartDaySameAsEndDay: boolean;
    isStartYearSameAsEndYear: boolean;

    fields = [
        'title',
        'description',
        'type',
        'images',
        'primaryImage',
        'guide',
        'accomodation',
        'transport',
        'amountMin',
        'amountMax',
        'price',
        'startDate',
        'endDate',
        'startTime',
        'endTime',
        'femalesOnly',
        'reserveSpotsOnly',
        'hidden',
        'fullyBooked',
        'difficulty',
        'registrationFee',
        'singleBedroomFee',
        'priceInformation',
        'plus',
        'files',
        'infoFile',
        'country',
        'bookingUrl',
        'partner',
        'newFromDate',
        'projectNumber',
        'ownerComment',
        'adminComment',
    ];

    constructor(data?) {
        super();

        this.initializeModel(data);

        if (this.newFromDate?.length && isBefore(new Date(), add(new Date(this.newFromDate), { days: 21 }))) {
            this.isNew = true;
        }

        if (data?.reservations) {
            this.reservationCount = data.reservations.length;
            this.reservations = data.reservations.map((reservation) => new Reservation(reservation));
        }

        if (data?.users?.length) {
            this.users = data.users.map((user) => new User(user));
        }

        if (data?.media?.length) {
            this.media = data.media.map((file) => new File(file));
        }

        if (data?.miscMedia?.length) {
            this.miscMedia = data.miscMedia.map((file) => new File(file));
        }

        if (data?.infoMedia) {
            this.infoMedia = new File(data.infoMedia);
        }

        if (data?.primaryMedia) {
            this.primaryMedia = new File(data.primaryMedia);
        }

        this.isStartDaySameAsEndDay = this._isStartDaySameAsEndDay(data);
        this.isStartYearSameAsEndYear = this._isStartYearSameAsEndYear(data);

        /**
         * Set the hour to 10 o'clock to make sure the timezone bug doesn't happen anymore.
         **/
        if (this.startDate) {
            this.startDate = moment(this.startDate).set({ hour: 10 }).format('YYYY-MM-DD HH:mm:ss');
        }

        /**
         * Set the hour to 10 o'clock to make sure the timezone bug doesn't happen anymore.
         **/
        if (this.endDate) {
            this.endDate = moment(this.endDate).set({ hour: 10 }).format('YYYY-MM-DD HH:mm:ss');
        }

        if (this._isStartYearSameAsEndYear(data) && !this._isStartDaySameAsEndDay(data)) {
            const startDate = moment(data.startDate).format('D MMMM');
            const startTime = data?.startTime ? moment(data.startTime).format('HH:mm') : '';
            const endDate = moment(data.endDate).format('D MMMM YYYY');
            const endTime = data?.endTime ? moment(data.endTime).format('HH:mm') : '';

            this.timespan = `${startDate} ${startTime} - ${endDate} ${endTime}`;
        } else if (!this._isStartYearSameAsEndYear(data) && !this._isStartDaySameAsEndDay(data)) {
            const startDate = moment(data.startDate).format('D MMMM YYYY');
            const startTime = data?.startTime ? moment(data.startTime).format('HH:mm') : '';
            const endDate = moment(data.endDate).format('D MMMM YYYY');
            const endTime = data?.endTime ? moment(data.endTime).format('HH:mm') : '';

            this.timespan = `${startDate} ${startTime} - ${endDate} ${endTime}`;
        } else if (this._isStartDaySameAsEndDay(data)) {
            const startDateData = data?.startDate || moment();
            const startDate = moment(startDateData).format('D MMMM YYYY');
            const startTime = data?.startTime ? moment(data.startTime).format('HH:mm') : '';
            const endTime = data?.endTime ? moment(data.endTime).format('HH:mm') : '';

            this.timespan = `${startDate} ${startTime} - ${endTime}`;
        }
    }

    getTypeColor() {
        switch (this.type) {
            case 'trip':
                return 'dark';

            case 'guiding':
                return 'light';

            case 'course':
                return 'light';
        }
    }

    getTypeBackground() {
        switch (this.type) {
            case 'trip':
                return '#FFEE66';

            case 'guiding':
                return '#38b938';

            case 'course':
                return '#cf6fef';
        }
    }

    private _isStartYearSameAsEndYear(form: Activity): boolean {
        const startDate = form?.startDate || moment();
        const endDate = form?.endDate || moment();

        const startYear = moment(startDate).format('YYYY');
        const endYear = moment(endDate).format('YYYY');

        if (startYear === endYear) {
            return true;
        }

        return false;
    }

    private _isStartDaySameAsEndDay(form: Activity): boolean {
        const startDate = form?.startDate || moment();
        const endDate = form?.endDate || moment();

        const startDay = moment(startDate).format('YYYY-MM-DD');
        const endDay = moment(endDate).format('YYYY-MM-DD');

        if (startDay === endDay) {
            return true;
        }

        return false;
    }
}

// <ion-card-subtitle *ngIf="isStartYearSameAsEndYear(form) && !isStartDaySameAsEndDay(form)"
// >{{ form.startDate | amDateFormat:'D MMMM' }}
// <ng-container *ngIf="form.startTime">{{ form.startTime }}</ng-container> - {{
// form.endDate | amDateFormat:'D MMMM YYYY' }}
// <ng-container *ngIf="form.endTime">{{ form.endTime }}</ng-container></ion-card-subtitle
// >

// <ion-card-subtitle
// *ngIf="!isStartYearSameAsEndYear(form) && !isStartDaySameAsEndDay(form)"
// >{{ form.startDate | amDateFormat:'D MMMM YYYY' }}
// <ng-container *ngIf="form.startTime">{{ form.startTime }}</ng-container> - {{
// form.endDate | amDateFormat:'D MMMM YYYY' }}
// <ng-container *ngIf="form.endTime">{{ form.endTime }}</ng-container></ion-card-subtitle
// >

// <ion-card-subtitle *ngIf="isStartDaySameAsEndDay(form)"
// >{{ form.startDate | amDateFormat:'D MMMM YYYY' }}
// <ng-container *ngIf="form.startTime && form.endTime"
//   >{{ form.startTime }} - {{ form.endTime }}</ng-container
// ></ion-card-subtitle
// >
