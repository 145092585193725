import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { TSuiteHttpService } from './t-suite-http.service';
import { TSuiteHttpConfigService, FOR_ROOT_OPTIONS } from './t-suite-http.config';
import { TSuiteHttpModuleOptions } from './symbols';

@NgModule({
    imports: [HttpClientModule],
    exports: [HttpClientModule],
    declarations: [],
    providers: [TSuiteHttpService],
})
export class TSuiteHttpModule {
    static forRoot(options?: TSuiteHttpModuleOptions): ModuleWithProviders<TSuiteHttpModule> {
        return {
            ngModule: TSuiteHttpModule,
            providers: [
                TSuiteHttpService,
                {
                    provide: FOR_ROOT_OPTIONS,
                    useValue: options,
                },
                {
                    provide: TSuiteHttpConfigService,
                    useFactory: provideHttpService,
                    deps: [FOR_ROOT_OPTIONS],
                },
            ],
        };
    }
}

export function provideHttpService(options: TSuiteHttpModuleOptions): TSuiteHttpConfigService {
    const service = new TSuiteHttpConfigService();

    service.setOptions(options);

    return service;
}
