import { Injectable } from '@angular/core';

import { State, StateContext, Selector, NgxsOnInit } from '@ngxs/store';

export interface AppStateModel {
    stateInitialized: boolean;
}

const defaults = {
    organisation: null,
    showTabs: true,
    stateInitialized: false,
};

@State<AppStateModel>({
    name: 'app',
    defaults,
})
@Injectable({ providedIn: 'root' })
export class AppState implements NgxsOnInit {
    constructor() {}

    @Selector()
    static stateInitialized({ stateInitialized }: AppStateModel): boolean {
        return stateInitialized;
    }

    ngxsOnInit({ dispatch, patchState }: StateContext<AppStateModel>) {
        patchState({ stateInitialized: true });
    }
}
